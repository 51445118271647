import sendXhrRequest from './request';
import Handlebars from './handlebars';
// TODO: verrà rimosso dopo l'aggiornamento delle dipendenze.
import 'regenerator-runtime/runtime';

let titoloTipologiaCustom;
let tipo;
let tipoForm;
let linkFormExt;
let hubspotFormAutosuggestionField;
let hubspotFormIdField;
let formHubspotDiv;
let listBbForms;
let autocompleteTopicsInput;
let autocompleteTopicsList;
let chipContainer;
let titoloTipologiaInput;
let updateHbFormsBtn;
let immagineRadio;
let immagineHidden;
let noImgCheckbox;
let immagineEl;
let testoPulsanteDiv;
let testoPulsanteInput;
let fraseIngaggioInput;
let dateTimeDiv;
let dataScadenzaDiv;
let dataInput;
let dataScadenzaInput;
let timeInput;
let immagineCta;
let formEspansoContainer;
let campangaSfdc;
let sfdcCampaignId;
let jolly;
let categoria;
const topicsAssociati = [];

const closeAutosuggestion = (ev) => {
  if (!ev.target.classList.contains('autosuggestion_span')) {
    if (listBbForms) {
      listBbForms.innerHTML = '';
    }
  }
};

const updateCounter = (el) => {
  const maxChar = el.dataset.max_char;
  const currentCharLenght = el.value.length;
  const span = el.parentNode.querySelector('span.counter');
  if (currentCharLenght > maxChar) {
    el.value = el.value.substring(0, maxChar);
    span.innerHTML = `${maxChar}/${maxChar}`;
  } else {
    span.innerHTML = `${currentCharLenght}/${maxChar}`;
  }
};

const changeTipo = (event) => {
  const el = event.target;
  const input = el.parentNode.parentNode.querySelector('input');
	const customTypes = ['6', '7', '8']; // other, autopromo, lgboost
  if (customTypes.includes(el.value)) {
    titoloTipologiaCustom.classList.remove('hide');
    testoPulsanteDiv.classList.remove('hide');
    titoloTipologiaInput.required = 'required';
    testoPulsanteInput.required = 'required';
  } else {
    titoloTipologiaCustom.classList.add('hide');
    testoPulsanteDiv.classList.add('hide');
    input.value = '';
    titoloTipologiaInput.required = '';
    testoPulsanteInput.required = '';
  }
  if (el.value === '2' || el.value === '3') {
    dataInput.required = 'required';
    timeInput.required = 'required';
    dateTimeDiv.classList.remove('hide');
    dataScadenzaDiv.classList.add('hide');
  } else {
    dateTimeDiv.classList.add('hide');
    dataScadenzaDiv.classList.remove('hide');
    dataInput.required = '';
    timeInput.required = '';
  }
};

const changeTipoForm = (event) => {
  const el = event.target;
  const input = linkFormExt.querySelector('input[name="link_form"]');
  if (el.value === '1') {
    formHubspotDiv.classList.add('hide');
    linkFormExt.classList.remove('hide');
    hubspotFormAutosuggestionField.value = '';
    hubspotFormIdField.value = '';
    input.required = 'required';
    hubspotFormIdField.required = '';
    hubspotFormAutosuggestionField.required = '';
    formEspansoContainer.classList.add('hide');
    campangaSfdc.classList.add('hide');
  } else {
    formHubspotDiv.classList.remove('hide');
    linkFormExt.classList.add('hide');
    formEspansoContainer.classList.remove('hide');
    campangaSfdc.classList.remove('hide');
    input.value = '';
    input.required = '';
    hubspotFormIdField.required = 'required';
    hubspotFormAutosuggestionField.required = 'required';
  }
};

const selectHbForm = (event) => {
  const el = event.target;
  const idForm = el.dataset.id;
  const titoloForm = el.dataset.titolo;
  hubspotFormAutosuggestionField.value = titoloForm;
  hubspotFormIdField.value = idForm;
  listBbForms.innerHTML = '';
};

const addEventListenerToAutosuggestion = () => {
  const forms = document.querySelectorAll('.autosuggestion_chbform');
  for (let i = forms.length - 1; i >= 0; i -= 1) {
    forms[i].addEventListener('click', selectHbForm);
  }
};

const successRequestHbForm = (response) => {
	const compiledTemplate = Handlebars.getTemplate('autocomplete_form_hubspot');
	const html = compiledTemplate({ response });

	listBbForms.innerHTML = html;
	addEventListenerToAutosuggestion();
};

const successRequestUpdateForm = (data) => {
  listBbForms.innerHTML = '';
  hubspotFormIdField.value = '';

  M.toast({ html: data.msg });
};

const showErrorMessage = (response) => {
	M.toast({ html: response.msg });
};

const autosuggestionHbForm = async (event) => {
	const query = event.target.value;
	if (query.length > 2) {
		const endpoint = `${window.location.origin}/api/searchform/${query}`;
		try {
			await fetch(endpoint)
				.then((response) => {
					if (response.status !== 200) {
						showErrorMessage({ msg: 'Nessun form trovato' });
					}
					return response.json();
				})
				.then(successRequestHbForm);
		} catch (error) {
			console.log(error);
		}
	} else {
		listBbForms.innerHTML = '';
		hubspotFormIdField.value = '';
	}
};

/**
 * Marca il topic selezionato per la cancellazione
 * @param {DOMelement} target elemento dell'oggetto event
 */
const deleteTopic = ({ target }) => {
  const { id } = target.dataset;
  const removeIndex = topicsAssociati.findIndex((item) => item.id === id);
  if (topicsAssociati[removeIndex].action === 'add') {
    topicsAssociati.splice(removeIndex, 1);
    return;
  }
  topicsAssociati[removeIndex].action = 'delete';
};

/**
 * Aggiunge alla lista dei topic il topic scelto dall'autocomplete.
 * @param {DOMelement} target elemento dall'oggetto event
 */
const selectTopic = ({ target }) => {
  const { id } = target.dataset;
  const { titolo } = target.dataset;
  const { type } = target.dataset;
  const compiledTemplate = Handlebars.getTemplate('topic_chips');
  const html = compiledTemplate({
    id,
    titolo,
    type,
  });
  chipContainer.innerHTML += html;
  autocompleteTopicsList.innerHTML = '';
  autocompleteTopicsInput.value = '';
  const topicIndex = topicsAssociati.findIndex((item) => item.id === id);
  if (topicIndex > -1) {
    const { action: currentAction } = topicsAssociati[topicIndex];
    if (currentAction === 'add') {
      topicsAssociati.splice(topicIndex, 1);
      return;
    }
    topicsAssociati[topicIndex].action = 'current';
    return;
  }
  topicsAssociati.push({
    id,
    type,
    name: titolo,
    action: 'add',
  });
};

const addEventListenerToAutosuggestionTopic = () => {
  const elememnts = document.querySelectorAll('.autosuggestion_topic');
  for (let i = elememnts.length - 1; i >= 0; i -= 1) {
    elememnts[i].addEventListener('click', selectTopic);
  }
};

const successRequestTopics = (data) => {
  const response = JSON.parse(data);
  const compiledTemplate = Handlebars.getTemplate('autocomplete_topics');
  const html = compiledTemplate({ response: response.results });
  autocompleteTopicsList.innerHTML = html;
  addEventListenerToAutosuggestionTopic();
};

const autosuggestionTopics = (event) => {
  const query = event.target.value;
  if (query.length > 2) {
    const endpoint = `${window.location.origin}/api/searchall/${query}`;
    const selectableTopics = topicsAssociati.filter((topic) =>
      ['current', 'add'].includes(topic.action)
    );
    const filter = `filter=${encodeURIComponent(JSON.stringify(selectableTopics))}`;
    sendXhrRequest(endpoint, filter, 'POST').then(successRequestTopics).catch(showErrorMessage);
  }
};


const updateHbForms = async () => {
	let offset = 0;
	let url;
	const requestArgs = {
		method: 'GET',
	};

	do {
		try {
			url = `${window.location.origin}/api/updatelimitedhbforms/?offset=${offset}`;
			await fetch(url, requestArgs)
				.then((response) => {
					if (response.status !== 200) {
						showErrorMessage({ msg: 'Nessun form trovato' });
						return;
					}

					return response.json();
				})
				.then((data) => {
					offset = data.offset;
				});
		} catch (error) {
			console.log(error);
			return;
		}
	} while (offset !== 0);

	successRequestUpdateForm({ msg: 'Aggiornamento lista form hubspot completato.' });
};

const successRequestCta = (data) => {
  const response = JSON.parse(data);
  M.toast({ html: response.msg });
  setTimeout(() => {
    const url = `${window.location.origin}/cta/${response.id}`;
    window.location.href = url;
  }, 1500);
};

/**
 * Restituisce il peso di default in base al tipo di CTA
 */
const ctaWeight = (tipoCta) => {
	let weight;
	switch (tipoCta) {
		case '0': // whitepaper
		case '1': // infographic
			weight = 2;
			break;
		case '2': // event
		case '3': // webinar
			weight = 3;
			break;
		case '4': // webcast
		case '5': // newsletter
		case '6': // other
		case '7': // autopromo
		case '8': // lgboost
		default:
			weight = 1;
	}
	return weight;
};

/**
 * Salva la cta nel backend.
 * Questa funzione viene usata sia per la creazione
 * che per gli update.
 */
const saveCta = (event) => {
  const saveBtn = event.target;
  const form = document.querySelector('.form_cta');
  const formElements = form.querySelectorAll('[required]');
  const ctaObject = {};
  const ctaErroObject = {};

  for (let i = formElements.length - 1; i >= 0; i -= 1) {
    const field = formElements[i];
    if (field.checkValidity() && field.name) {
      ctaObject[field.name] = field.value;
      field.classList.remove('error-input');
    } else {
      field.classList.add('error-input');
      ctaErroObject[field.name] = field.value;
    }
  }

  const isNewCta = ctaObject.id === '0';
  delete ctaObject.data;
  delete ctaObject.time;
  let tempData = dataInput.value.split('/');
  tempData = tempData.reverse();
  const soloDataInizio = tempData.join('-');
  const dataInizio = `${tempData.join('-')} ${timeInput.value}:00`;
  ctaObject.data_inizio = dataInizio;

  let tempDataScadenza = dataScadenzaInput.value.split('/');
  tempDataScadenza = tempDataScadenza.reverse();
  let dataScadenza = tempDataScadenza.join('-');
  if (tipo.value === '2' || tipo.value === '3') {
    dataScadenza = soloDataInizio;
  }

  const status = form.querySelector('input[name="status"]').checked ? 1 : 0;
  const showTopics = form.querySelector('input[name="show_topics"]').checked ? 1 : 0;
  let formEspanso = form.querySelector('input[name="form_espanso"]').checked ? 1 : 0;
	const isJolly = form.querySelector('[name="jolly"]').checked ? 1 : 0;
  const isFpa = form.querySelector('[name="fpa"]').checked ? 1 : 0;
	const isCategoria = form.querySelector('[name="categoria"]').checked ? 'engine' : null;
  ctaObject.status = status;
  ctaObject.show_topics = showTopics;
	ctaObject.jolly = isJolly;
  ctaObject.fpa = isFpa;
	ctaObject.categoria = isCategoria;
  ctaObject.sfdc_campaign_id = sfdcCampaignId.value;
  ctaObject.frase_ingaggio = fraseIngaggioInput.value;
  if (dataScadenza !== '') {
    ctaObject.data_scadenza_cta = dataScadenza;
  }
  if (tipoForm.value === '1') {
    formEspanso = 0;
    ctaObject.sfdc_campaign_id = '';
  }
  ctaObject.form_espanso = formEspanso;

  let immagineRadioSetting;
  for (let i = immagineRadio.length - 1; i >= 0; i -= 1) {
    if (immagineRadio[i].checked) {
      immagineRadioSetting = immagineRadio[i].value;
    }
  }

  if (!immagineRadioSetting || (immagineRadioSetting === '1' && immagineHidden.value === '')) {
    immagineCta.classList.add('error-input');
    M.toast({ html: LANG.error_missing_values });
    return;
  }
  immagineCta.classList.remove('error-input');

  // Può capitare che sia compilato il nome del form di hubspot senza che venga associato ad un id, in questo caso ritorno un errore
  const formHubspot = form.querySelector("input[name=hubspot_form]");
  const formHubspotId = form.querySelector("input[name=hb_form_id]");
  // Se il titolo del form Hubspot è valorizzato ed è più lungo di 3 caratteri setto il fail a false.
  const formHubspotTitleFail = formHubspot.value && formHubspot.value.length > 3 ? false : true;
  // Se l'ID del form Hubspot è valorizzato ed è più lungo di 3 caratteri (eliminando anche gli spazi) setto il fail a false.
  const formHubspotIdFail = formHubspotId.value && formHubspotId.value.replace(/\s/g, '').length > 3 ? false : true;
  if(tipoForm.value === '0' && ( formHubspotTitleFail || formHubspotIdFail )) {
    formHubspot.classList.add('error-input');
    M.toast({ html: LANG.error_wrong_values });
    return;
  }
  formHubspot.classList.remove('error-input');

  ctaObject.immagine = immagineHidden.value;
  const topics = topicsAssociati
    .filter((topic) => topic.action !== 'current')
    .map((topic) => ({ ...topic, peso: ctaWeight(ctaObject.tipo) }));
  // Salvo una mappa ID/nome topic nella tabella CTA
  ctaObject.topic_names = topicsAssociati
  .filter((topic) => topic.action !== 'delete')
  .sort((topic1, topic2) => topic1.id - topic2.id)
  .map((topic) => `${topic.type[0].toUpperCase()}_${topic.id}#${topic.name}`)
  .join(',');
  if (form.checkValidity()) {
    //Disabilito il tasto per evitare salvataggi multipli
    saveBtn.setAttribute('disabled', '');
    
    const payload = { cta: ctaObject, association: topics };
    const data = `data=${encodeURIComponent(JSON.stringify(payload))}`;
    const endpoint = `${window.location.origin}/cta/write/`;
    sendXhrRequest(endpoint, data, 'POST')
      .then(successRequestCta)
      .catch( (msg) => {
        //Riabilito il tasto
        saveBtn.removeAttribute('disabled');
        showErrorMessage(msg);
      });
  } else {
    M.toast({ html: LANG.error_missing_values });
  }
};

const removeImage = () => {
  immagineHidden.value = '';
  immagineEl.src = `${window.location.origin}/assets/images/img_placeholder.png`;
};

/**
 * Fa il debounce della funzione da eseguire al typing sul campo di ricerca
 * @param {*} func - funzione di cui effettuare il debounce
 * @param {int} wait - tempo di attesa in millisecondi
 * @returns {Function} - funzione da eseguire
 */
 const debounce = (func, wait) => {
  let timeout;
  return function (...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  }
}

/**
 * Init della pagina cta
 */
const initCta = () => {
  const counters = document.querySelectorAll('.with-counter');
  const saveBtn = document.querySelector('.save-cta');
  tipo = document.querySelector('[name="tipo"]');
  titoloTipologiaCustom = document.querySelector('.tipologia_custom');
  tipoForm = document.querySelector('[name="tipo_form"]');
  linkFormExt = document.querySelector('.link-form-ext');
  hubspotFormAutosuggestionField = document.querySelector('input[name="hubspot_form"]');
  hubspotFormIdField = document.querySelector('input[name="hb_form_id"]');
  formHubspotDiv = document.querySelector('.form-hubspot');
  listBbForms = document.querySelector('.list_hb_forms');
  autocompleteTopicsInput = document.querySelector('.autocomplete-topics');
  autocompleteTopicsList = document.querySelector('.topic_lists');
  chipContainer = document.querySelector('.topic-associati');
  titoloTipologiaInput = document.querySelector('input[name="titolo_tipologia_custom"]');
  updateHbFormsBtn = document.querySelector('.update-hb-forms');
  immagineRadio = document.querySelectorAll('input[name="immagine_settings"]');
  immagineHidden = document.querySelector('input[name="immagine"]');
  noImgCheckbox = document.querySelector('.no-image-ck');
  immagineEl = document.querySelector('.immagine-cta');
  testoPulsanteDiv = document.querySelector('.testo-pulsante-div');
  testoPulsanteInput = document.querySelector('input[name="testo_pulsante"]');
  fraseIngaggioInput = document.querySelector('input[name="frase_ingaggio"]');
  dateTimeDiv = document.querySelector('.eventi-data-wrapper');
  dataScadenzaDiv = document.querySelector('.data-scadenza-wrapper');
  dataInput = document.querySelector('input[name="data"]');
  timeInput = document.querySelector('input[name="time"]');
  formEspansoContainer = document.querySelector('.form_espanso_container');
  campangaSfdc = document.querySelector('.campanga_sfdc');
  sfdcCampaignId = document.querySelector('input[name="sfdc_campaign_id"]');
  dataScadenzaInput = document.querySelector('input[name="data_scadenza_cta"]');
  jolly = document.querySelector('[name="jolly"]');
  categoria = document.querySelector('[name="categoria"]');

  const mesi = [
    LANG.cal_january,
    LANG.cal_february,
    LANG.cal_march,
    LANG.cal_april,
    LANG.cal_mayl,
    LANG.cal_june,
    LANG.cal_july,
    LANG.cal_august,
    LANG.cal_september,
    LANG.cal_october,
    LANG.cal_november,
    LANG.cal_december,
  ];
  const mesiShort = [
    LANG.cal_jan,
    LANG.cal_feb,
    LANG.cal_mar,
    LANG.cal_apr,
    LANG.cal_may,
    LANG.cal_jun,
    LANG.cal_jul,
    LANG.cal_aug,
    LANG.cal_sep,
    LANG.cal_oct,
    LANG.cal_nov,
    LANG.cal_dec,
  ];
  const giorni = [
    LANG.cal_sun,
    LANG.cal_mon,
    LANG.cal_tue,
    LANG.cal_wed,
    LANG.cal_thu,
    LANG.cal_fri,
    LANG.cal_sat,
  ];
  const giorniIniziali = [
    LANG.cal_su,
    LANG.cal_mo,
    LANG.cal_tu,
    LANG.cal_we,
    LANG.cal_th,
    LANG.cal_fr,
    LANG.cal_sa,
  ];

  const dataEl = document.querySelectorAll('.datepicker');
  M.Datepicker.init(dataEl, {
    showClearBtn: true,
    minDate: new Date(),
    firstDay: 1,
    format: 'dd/mm/yyyy',
    i18n: {
      cancel: LANG.cancel,
      clear: LANG.clear_data,
      monthsShort: mesiShort,
      months: mesi,
      weekdaysShort: giorni,
      weekdaysAbbrev: giorniIniziali,
    },
  });

  const timeEl = document.querySelectorAll('.timepicker');
  M.Timepicker.init(timeEl, {
    twelveHour: false,
  });

  const tooltips = document.querySelectorAll('.tooltipped');
  if (tooltips) {
    M.Tooltip.init(tooltips, {});
  }

  immagineCta = document.querySelector('.immagine-cta');

  document.querySelector('body').addEventListener('click', closeAutosuggestion);

  if (counters) {
    for (let i = counters.length - 1; i >= 0; i -= 1) {
      counters[i].addEventListener('keyup', (e) => {
        updateCounter(e.target);
      });
      updateCounter(counters[i]);
    }
  }

  if (chipContainer) {
    const chipsTopicsAssociati = chipContainer.querySelectorAll('.chip');
    if (chipsTopicsAssociati) {
      for (let i = chipsTopicsAssociati.length - 1; i >= 0; i -= 1) {
        topicsAssociati.push({
          id: chipsTopicsAssociati[i].dataset.id,
          type: chipsTopicsAssociati[i].dataset.type,
          name: chipsTopicsAssociati[i].dataset.name,
          action: 'current',
        });
      }
    }
    chipContainer.addEventListener('click', (event) => {
      if (event.target.matches('.remove-topic')) {
        event.preventDefault();
        deleteTopic(event);
      }
      return false;
    });
  }


  if (saveBtn) {
    saveBtn.addEventListener('click', saveCta);
  }
  if (tipo) {
    tipo.addEventListener('change', changeTipo);
  }
  if (tipoForm) {
    tipoForm.addEventListener('change', changeTipoForm);
  }
  if (hubspotFormAutosuggestionField) {
    hubspotFormAutosuggestionField.addEventListener('keyup', debounce(autosuggestionHbForm, 500));
  }
  if (autocompleteTopicsInput) {
    autocompleteTopicsInput.addEventListener('keyup', autosuggestionTopics);
  }
  if (updateHbFormsBtn) {
    updateHbFormsBtn.addEventListener('click', async (event) => {
      event.preventDefault();
      event.target.classList.add('loading');
      try {
        await updateHbForms();
      } catch (error) {
        console.error('Errore durante il caricamento della lista dei form: ', error);
      } finally {
        event.target.classList.remove('loading');
      }
    });
  }
  if (noImgCheckbox) {
    noImgCheckbox.addEventListener('click', removeImage);
  }
};

export default initCta;
