
let buttonSave;
let formHasChanged = false;

const controlloSalvataggioInit = () => {
	const inputElements = document.querySelectorAll('main input[type="text"]');
	const selectElements = document.querySelectorAll('main select');
	const clickedElements = document.querySelectorAll('main a:not(.chip.chip-topic), main button, main i,main input[type="checkbox"], main input[type="radio"]');
	buttonSave = document.querySelector('.save-btn');
	for (let i = inputElements.length - 1; i >= 0; i--) {
		inputElements[i].addEventListener('keydown',inputChanged);
	}
	if ( buttonSave ) {
		buttonSave.addEventListener('click',removeBeforeUnload);
	}
	for (let i = clickedElements.length - 1; i >= 0; i--) {
		clickedElements[i].addEventListener('click',inputChanged);
	}
	for (let i = selectElements.length - 1; i >= 0; i--) {
		selectElements[i].addEventListener('change',inputChanged);
	}
};

const inputChanged = (e) => {
	if ( ! formHasChanged && buttonSave) {
		formHasChanged = true;
		window.addEventListener('beforeunload', messageBeforeUnload);
		buttonSave.disabled = false
	}
}

const removeBeforeUnload = () => {
	window.removeEventListener("beforeunload", messageBeforeUnload);
}

const messageBeforeUnload = (event) => {
	event.returnValue = '';
}

export default controlloSalvataggioInit;
