
let imageSelected,selectedImage,insertBtn,uploadFile,imgContainer,ajaxContainer,paginationLink,imagePreview,immagineCta,modalClose,immagineHidden;
const urlBase = window.location.origin;

const initMediaLibrary = () => {
	ajaxContainer = document.querySelector('.ajax-container');
	if ( ajaxContainer ) {
		loadImages(1);
	}
	immagineCta = document.querySelector('.immagine-cta');
	immagineHidden = document.querySelector('input[name="immagine"]');
	modalClose = document.querySelector('.modal-close.btn');
}

// funzione eseguita al click dell'immagine
function imgClick(e) {
	selectedImage = document.querySelector('.img-box.selected');
	if ( selectedImage && selectedImage != e.target ) {
		selectedImage.classList.remove('selected');
	}
	e.target.classList.toggle('selected');
	if ( e.target.classList.contains( 'selected' ) ) {
		imageSelected.value = e.target.dataset.src;
		imagePreview.style.backgroundImage = 'url(\'' + e.target.dataset.src + '\')';
	} else {
		imageSelected.value = '';
	}
}

// funzione eseguita al click del pulsante insert
function insertImage() {
	if ( imageSelected.value == '' ) {
		  M.toast({html: LANG.error_select_image});
	} else {
		immagineCta.src = imageSelected.value;
		immagineCta.classList.remove('error-input');
		immagineHidden.value = imageSelected.value;
		modalClose.click();
	}
}

// funzione che gestice l'upload del file
function upload() {
	const file = uploadFile.files[0];
	const mime_types = [ 'image/jpeg', 'image/png', 'image/gif' ];
	if('undefined' === typeof file.type || mime_types.indexOf(file.type) == -1) {
		M.toast({html: LANG.error_format_image});
		uploadFile.value = ''; 
		return;
	}
	const data = new FormData();
	data.append('file', file);
	const request = new XMLHttpRequest();
	request.addEventListener('load', function(e) {
		const response = request.response;
		M.toast({html: response.msg});
		if ( response.success ) {
			imageSelected.value = response.src;
			const div = document.createElement('div');
			div.className = 'col s2';
			const img = document.createElement('div');
			img.className = 'img-box hide';
			img.dataset.src = response.src;
			img.style.backgroundImage = 'url(\'' + response.src + '\')';
			div.appendChild(img);
			imgContainer.appendChild(div);
			div.addEventListener('click',imgClick);
			document.querySelector('[data-src="' + response.src + '"]').click();
		}
	});
	request.responseType = 'json';
	request.open('post', urlBase + '/media/upload'); 
	request.send(data);
}

// funzione che gestisce il caricamento asincrono delle immagini e paginazione
function loadImages(page) {
    const request = new XMLHttpRequest();
    request.onreadystatechange = function() {
        if (request.readyState == XMLHttpRequest.DONE) {
	        if (request.status == 200) {
	         	ajaxContainer.innerHTML = request.response;
	            loadEventHandlers();
	        } else if (request.status == 400) {
	            console.log('errore alle api di media');
       		}
        }
    };
	request.open('GET', urlBase + '/media/ajax_load_library/?per_page=' + page, true);
	request.send();
}

// carico gli event handlers
function loadEventHandlers() {
	const images = document.querySelectorAll('.img-box');
	imageSelected = document.querySelector('#url');
	insertBtn = document.querySelector('.insert-btn');
	uploadFile = document.querySelector('#filetoupload');
	imgContainer = document.querySelector('.image-collection');
	paginationLink = document.querySelectorAll('.pagination a');
	imagePreview = document.querySelector('.image_preview');
	if ( images ) {
		for (let i = images.length - 1; i >= 0; i--) {
			images[i].addEventListener('click', imgClick);
		}
	}
	if ( insertBtn ) {
		insertBtn.addEventListener('click', insertImage);
	}
	if ( uploadFile ) {
		uploadFile.addEventListener('change', upload);
	}
	if ( paginationLink ) {
		for (let i = paginationLink.length - 1; i >= 0; i--) {
			paginationLink[i].addEventListener('click', paginationClick);
		}
	}
}

// funzione eseguita al click della paginazione
function paginationClick(e) {
	e.preventDefault();
	const page = e.target.dataset.ciPaginationPage;
	loadImages(page);
}
	
export default initMediaLibrary;
	
